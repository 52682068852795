import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import WorkEntries from 'atomic-design/organisms/work-entries';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';
import PageHeader from 'atomic-design/atoms/page-header';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const workListQuery = graphql`
  query workListQuery {
    allContentfulCaseStudy(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          title
          slug
          subtitle
          LimitedAbstract
          body {
            json
          }
          thumbnail {
            contentful_id
            title
            file {
              url
            }
          }
          technologies {
            name
            url
            image {
              file {
                url
              }
              contentful_id
              description
            }
          }
          filteredIn {
            optionName
          }
        }
      }
    }
    allContentfulPage(filter: { url: { eq: "work" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }
  }
`;

const Work = props => {
  const { location } = props;
  const pageHeaderData = props.data.allContentfulPage.edges[0].node;
  const metatagImage = props.data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = props.data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = props.data.allContentfulPage.edges[0].node.metatagTitle;

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      <PageSection padding={`0 0 ${theme.newTheme.spacing(22)} 0`}>
        <PageHeader
          title={pageHeaderData.headerSubtitle}
          subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
          description={pageHeaderData.headerDescription.headerDescription}
          color={theme.newTheme.color.gradient.text}
        />
      </PageSection>

      <PageSection padding={`0 0 ${theme.newTheme.spacing(22)} 0`}>
        <WorkEntries works={props.data.allContentfulCaseStudy.edges} />
      </PageSection>
    </Layout>
  );
};

export default Work;
